html, body, .container {
  height: 100%;
  min-height: 100vh;
}

.text {
  text-align: center;
  font-size: xx-large;
  padding-top: 11rem;
}

/* UsersCrud.css */

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.user-table th,
.user-table td {
  padding: 12px 15px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.user-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.edit-btn,
.delete-btn {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.edit-btn {
  background-color: #4caf50;
}

.delete-btn {
  background-color: #f44336;
  margin-left: 10px;
}

.edit-btn:hover,
.delete-btn:hover {
  background-color: #333;
}

.no-users {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

@import '~react-toastify/dist/ReactToastify.min.css';

